/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

// Important for CDN in production
const uri = new URL(document.querySelector('#application-js').src)
__webpack_public_path__ = `${uri.protocol}//${uri.host}/packs/`

import Vue from 'vue'
import App from '../app.vue'
import VueClipboard from 'vue-clipboard2'

const csrf = document.querySelector("meta[name=csrf-token]").content

Vue.use(VueClipboard)

window.Vue = Vue

const initApp = async () => {
  const { default: vuetify} = await import(/* webpackChunkName: 'vuetify' */'../plugins/vuetify')
  const { default: i18n } = await import(/* webpackChunkName: 'i18n' */'../plugins/i18n')
  const { default: vResources } = await import(/* webpackChunkName: 'vuetify-resources' */'../plugins/vuetifyResources')
  const { default: store } = await import(/* webpackChunkName: 'store' */'../store/index')
  const { initRouter } = await import(/* webpackChunkName: 'router' */'../router')
  const router = await initRouter()

  store.commit("InitApp", {csrf})

  const _vm = new Vue({
    vuetify,
    vResources,
    i18n,
    el: '#app',
    router,
    store,
    render: h => h(App)
  })
}

if(['complete', 'loaded', 'interactive'].includes(document.readyState)) {
  initApp()
} else {
  window.addEventListener('DOMContentLoaded', initApp)
}