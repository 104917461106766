<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>
import(/* webpackChunkName: 'vuetify-css', webpackPreload: true */ 'vuetify/dist/vuetify.min.css')

export default {
  name: "App",
  data() {
    return {
      subscription: undefined,
    }
  },
  mounted() {
    document.addEventListener("visibilitychange", this.onVisibilityChange)
    this.validateSession()
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.onVisibilityChange)
  },
  methods: {
    onVisibilityChange() {
      if (document.visibilityState === 'visible') {
        this.validateSession()
      }
    },
    async validateSession() {
      if (this.$store.getters['auth/isLogged']) {
        // axios
        const fetcher = this.$vResources.dataFetcher
        // Validate session, will logout automatically if session invalid due to interceptors
        // Refer to VResources/config.js
        const res = await fetcher("/api/validate/user").catch(e=>e)

        if (res instanceof Error) {
          await this.$store.dispatch('auth/sessionLogout')
        } else {
          const {
            data: {
              data
            }
          } = res
          
          this.$store.commit('auth/setLoginUser', data)
        }
      }
    }
  },
};
</script>